import axios from "axios";
import { RSVPConfig } from "../models/RSVPConfig";

import { config } from '../config';

const getMarketingEventRSVPQuestionnaireHdr = {
    'cache-control' : 'max-age=0',
    'signature': 'VAtsgpWlpNGk0XRMD1mty+DKzTvrxzZC+2qYwQLIqf/ctxeWx47/SX6iQkuHBlHCyvRYxDoxpFTK3pfq+iIFxcdRfM9SzYQ6aJ8P+i+vW1z/eNZMJO37yV96Vez5xZnvGSbELb6SwlmEas096Yg/sQnNmH9xRRHZdONe8x1EKt+aHwtMgVk7QZrpq01XdMQ+snBQ1GDc1FktlJx1hYex6ENOEUVCJYj0SnlyAMuQsRrHNQC7ba6iFYuUQGhqC6m56TvjH48HMLRWbcVaJEUkZQfaDsEeAM7PjbsS+HgDLh+1Goqs4aq1oO2QaiagGuzTtLttATTm0R8j9DpV+kEMdQ==',
    'appname': 'rsvp',
    'subjectid': '4fd424b68b9319e0fef77c06a315d1fZ',
    'timestamp': '2017-07-31 10:52:31',
    'accept': 'application/json',
    'consumer': 'id=A0268981-FA86TI9JVGPM183Q;type=SERVER',
    'content-type': 'application/json',
    'authz': 'id=000030276;ctx=DIST.FMID'    
};

const retrieveMarketingEventRSVPIdHdr = {
    'cache-control' : 'max-age=0',
    'accept': 'application/json',
    'consumer': 'id=A1277739-H5PA0VKQ2S5S4S40;type=WEB',
    'content-type': 'application/json',
    'authz': 'id=000030276;ctx=DIST.FMID'    
};

const saveRSVPQuestionaireHdr = {
    'cache-control': 'max-age=0',
    'signature': 'VAtsgpWlpNGk0XRMD1mty+DKzTvrxzZC+2qYwQLIqf/ctxeWx47/SX6iQkuHBlHCyvRYxDoxpFTK3pfq+iIFxcdRfM9SzYQ6aJ8P+i+vW1z/eNZMJO37yV96Vez5xZnvGSbELb6SwlmEas096Yg/sQnNmH9xRRHZdONe8x1EKt+aHwtMgVk7QZrpq01XdMQ+snBQ1GDc1FktlJx1hYex6ENOEUVCJYj0SnlyAMuQsRrHNQC7ba6iFYuUQGhqC6m56TvjH48HMLRWbcVaJEUkZQfaDsEeAM7PjbsS+HgDLh+1Goqs4aq1oO2QaiagGuzTtLttATTm0R8j9DpV+kEMdQ==',
    'appname': 'rsvp',
    'subjectid': '4fd424b68b9319e0fef77c06a315d1fZ',
    'timestamp': '2017-07-31 10:52:31',
    'accept': 'application/json',
    'consumer': 'id=A1277739-H5PA0VKQ2S5S4S40;type=SERVER',
    'content-type': 'application/json',
    'authz': 'id=30276;ctx=DIST.FMID'
}


function statCd02Convert(dnsCode) {
    var cmsg = '';

    switch (dnsCode) {
        case '5048':
            cmsg = "This event has already occurred, and responses are no longer being collected. Please contact your advisor with any questions.";
            break;

        case '5049':
        case '51000':
            cmsg = "This event is no longer active. Please contact your advisor with any questions.";
            break;

        case '51010':
        case '51020':
        case '51030':
        case '51050':
            cmsg = "The configuration of this questionaire is invalid.";
            break;

        case '51040':
            cmsg = "Multiple answers given to question.";
            break;

        case '51070':
            cmsg = "No answer to mandatory question.";
            break;
        
        case '51060':
            cmsg = "Invalid answer number given to question.";
            break;

        default:
            cmsg = "Unknown error returned"

    }

    return cmsg;
    
}


export async function retrieveMarketingEventInviteeByRSVPIdentifier (rsvpId, setVals, errorLoadingEvent) {
    try {
        let res = await axios.get(config.retrieveMarketingEventInviteeByRSVPIdentifierURL + rsvpId,
        {
           headers: retrieveMarketingEventRSVPIdHdr
        });
        if (res.data.d === undefined) { 
            return { status: false, evtId: null, inviteeId: null, errMsg: 'Error loading RSVPId ' + rsvpId};
        }
        else {
            if (res.data.d.statCd === "0000") {
                return { status: true, evtId: res.data.d.evntId, inviteeId: res.data.d.inviteeId, errMsg: 'Error loading RSVPId ' + rsvpId};
            }
            else {
                const eMsg = statCd02Convert(res.data.d.explanations.results[0].explCd);
                console.log(res.data.d.explanations.results[0].explCd + ' ' + res.data.d.explanations.results[0].explDesc);
                return { status: false, evtId: null, inviteeId: null, errMsg: eMsg};
            }
            //setVals(res.data.d.evntId, res.data.d.inviteeId);
        }
    } catch (error) {
        console.log(error);
        return { status: false, evtId: null, inviteeId: null, errMsg: error};
    };
}
    
export async function getRSVPQuestionaire(eventId, setRSVPConfig, showMessage, errorLoadingEvent) {
    await axios.get(config.getRSVPConfigURL + eventId,
    {
       headers: getMarketingEventRSVPQuestionnaireHdr
    })
    .then(res => {
        const rsvpConfig = new RSVPConfig(eventId);
        if (res.data.d.event.questions.results[0].qstnNbr === null) {
            errorLoadingEvent({ message: `Unable to find questionaire for event: ${eventId}.`})
        }
        else {
            rsvpConfig.loadFromJSON(eventId, res.data.d.event);
        }
        setRSVPConfig(rsvpConfig);

    }, (error) => {
        errorLoadingEvent(error);
    });
}

export async function saveRSVPQuestionaire(evntId, inviteeId, answers, showMessageSuccess, showMessageFailure) {

    const body = {
        evntId: evntId,
        inviteeId: inviteeId,
        choices: answers
    }

    await axios.post(config.saveRSVPConfigURL, body, 
    {
       headers: saveRSVPQuestionaireHdr
    })
    .then(res => {
        if (res.data.d.statCd === "0000") {
            showMessageSuccess("Success! Your RSVP has been successfully submitted. Please contact the event host if you have any questions or need to make changes to your reservation.");
        }
        else {
            const eMsg = statCd02Convert(res.data.d.explanations.results[0].explCd);
            console.log(res.data.d.explanations.results[0].explCd + ' ' + res.data.d.explanations.results[0].explDesc);
            showMessageFailure(eMsg,true);
        }
    }, (error) => {
        console.log(error);
        showMessageFailure("Error saving RSVP responses",true);
    });
}

